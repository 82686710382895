import React, { Component, Fragment } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import TabWorkWithUs from "../elements/tab/TabWorkWithUs";
import { FiCheck, FiPlus } from "react-icons/fi";


class WorkWithUs extends Component{
    render(){
        let jobPositions = [
            {
                styles: 'pt--120 pb--80',
                title: 'Software Developer',
                description: <Fragment>Ti piacciono le sfide? Cerchi nuovi stimoli e un team che possa aiutarti nella tua crescita?<br/>Inviaci la tua candidatura, stiamo cercando nuovi elmenti da inserire nel nostro organico.</Fragment>,
                image: {
                    fileName: 'developer.jpg',
                    alt: 'Lavora con noi - Software Developer',
                    title: 'Lavora con noi - Software Developer',
                    className: 'align-items-top'
                },
                layout: 'left-to-right',
                items: [
                    { 
                        label: 'Requisiti', 
                        content: <Fragment>
                            <ul class="work-with-us-list">
                                    <li><FiCheck/> Conoscenza dei pattern di sviluppo OOP, MVC, MVVM</li>
                                    <li><FiCheck/> Conoscenza e uso di GIT e dei principali workflow ad esso collegati</li>
                                    <li><FiCheck/> Esperienza nella progettazione ed implementazione di database relazionali (MySQL, SQL Server) e non relazionali (MongoDB, Elastic Search)</li>
                                    <li><FiCheck/> Familiarità con lo sviluppo di Web API REST</li>
                                    <li><FiCheck/> Familiarità con lo sviluppo front-end di applicazioni web</li>
                                    <li><FiCheck/> Ottime competenze in uno o più’ dei seguenti linguaggi di programmazione/scripting:
                                        <ul>
                                            <li>Javascript</li>
                                            <li>Typescript</li>
                                            <li>Angular</li>
                                            <li>Python</li>
                                            <li>C#</li>
                                            <li>React</li>
                                            <li>PHP</li>
                                        </ul>
                                    </li>
                                    <li><FiCheck/> Determinazione e proattività</li><li>
                                    </li><li><FiCheck/> Dinamicità</li><li>
                                    </li><li><FiCheck/> Forte orientamento all'obiettivo</li><li>
                                    </li><li><FiCheck/> Buone capacità relazionali e di comunicazione </li><li>
                                    </li><li><FiCheck/> Propensione al lavoro di team</li><li>
                                    </li><li><FiCheck/> Flessibilità, proattività e predisposizione al lavoro per obiettivi</li><li>
                                    </li><li><FiCheck/> Forte attinenza al problem solving</li><li>
                                </li>
                            </ul>
                        </Fragment> 
                    },
                    { 
                        label: 'Plus', 
                        content: <Fragment>
                            <ul class="work-with-us-list">
                                <li><FiPlus/> Esperienza o predisposizione a lavorare con metodologie Agile (Scrum, Kanban)</li>
                                <li><FiPlus/> Familiarità con infrastrutture cloud (Azure, AWS) e i servizi offerti (API, Blob Storage, servizi cognitivi...)</li>
                                <li><FiPlus/> Familiarità con lo sviluppo di applicazioni mobile native e ibride</li>
                                <li><FiPlus/> Esperienza con soluzioni "container-based" come Docker e Kubernetes </li>
                            </ul>
                        </Fragment> 
                    },
                    { 
                        label: 'Inclusioni', 
                        content: <Fragment><p>Si offre una retribuzione adeguata all'esperienza, ambiente stimolante, flessibilità e ottime opportunità di carriera.</p></Fragment> 
                    }
                ]
            }, 
            {
                styles: 'pt--40 pb--120',
                title: 'Inside Sales & Operation',
                description: 'Cerchiamo nuovi talenti che abbiano compentenze con il mercato B2B e che sappiano muoversi nella proposizione di servizi e soluzioni IT ad alto valore tecnologico.',
                image: {
                    fileName: 'sales.jpg',
                    alt: 'Lavora con noi - Sales',
                    title: 'Lavora con noi - Sales',                    
                    className: 'align-items-top'
                },
                layout: 'right-to-left',
                items: [
                    { 
                        label: 'Occupazioni', 
                        content: <Fragment>
                            <ul class="work-with-us-list">
                                <li><FiCheck/> Attività di Business development telefonica e tramite canali digitali;</li>
                                <li><FiCheck/> Preparazione offerte e supporto commerciale;</li>
                                <li><FiCheck/> Mantenimento delle relazioni con i Partner;</li>
                                <li><FiCheck/> Followup e contatto telefonico;</li>
                            </ul>
                        </Fragment> 
                    },
                    { 
                        label: 'Requisiti', 
                        content: <Fragment>
                            <ul class="work-with-us-list">
                                <li><FiCheck/> Esperienza nella vendita telefonica</li>
                                <li><FiCheck/> Residente nella provincia di Milano o in zone limitrofe</li>
                                <li><FiCheck/> Puntualità, precisione e affidabilità</li>
                                <li><FiCheck/> Ottima conoscenza della lingua inglese</li>
                                <li><FiCheck/> Dimestichezza nell'utilizzo degli strumenti informatici e del pacchetto Office</li>
                            </ul>
                        </Fragment> 
                    },
                    { 
                        label: 'Plus', 
                        content: <Fragment>
                            <ul class="work-with-us-list">
                                <li><FiPlus/> Determinazione e proattività</li>
                                <li><FiPlus/> Dinamicità</li>
                                <li><FiPlus/> Forte orientamento all'obiettivo</li>
                                <li><FiPlus/> Propensione al cliente</li>
                                <li><FiPlus/> Spiccate doti comunicative</li>
                            </ul>
                        </Fragment> 
                    },
                    { 
                        label: 'Inclusioni', 
                        content: <Fragment><p>Si offre una retribuzione adeguata all'esperienza, ambiente stimolante, flessibilità e ottime opportunità di carriera.</p></Fragment>
                    }
                ]
            }
        ];
        return(
            <React.Fragment>
                <PageHelmet title="BeatData" description="Lavora con noi" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Lavora con noi</h2>
                                    <p>Sei alla ricerca di nuove sfide?<br/>Proponici la tua candidatura, stiamo cercando nuovi talenti da inserire nel nostro team.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Job Position Area */}
                {jobPositions.map((position , index) => (
                    <div id={`position-${index}`} className="fix">
                        <div className={`about-area bg_color--1 ${position.styles}`}>
                            <div className="about-wrapper">
                                <div className="container">
                                    <div className="row row--35 align-items-top">
                                        {position.layout === 'left-to-right' ? 
                                            <>
                                                <div className="col-lg-5">
                                                    <div className="thumbnail">
                                                        <img className="w-100" src={`/assets/images/work-with-us/${position.image.fileName}`} alt={`${position.image.alt}`} title={`${position.image.title}`}/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7">
                                                    <div className="about-inner inner">
                                                        <div className="section-title">
                                                            <h2 className="title">{position.title}</h2>
                                                            <p className="description">{position.description}</p>
                                                        </div>
                                                        <div className="row mt--30">
                                                            <TabWorkWithUs listStyle="tab-style--1" itemStyle="" items={position.items}/>
                                                        </div>
                                                    </div>                                                                                                   
                                                    <div className="row mt--50">                                                    
                                                        <div className="col-lg-12">
                                                            <a className="rn-button-style--2 btn-sm btn-solid" href="mailto:info@beatdata.it" target="_blank" rel="noopener noreferrer">Invia Candidatura</a>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </>                                        
                                        :  
                                        <>
                                            <div className="col-lg-7">
                                                <div className="about-inner inner">
                                                    <div className="section-title">
                                                        <h2 className="title">{position.title}</h2>
                                                        <p className="description">{position.description}</p>
                                                    </div>
                                                    <div className="row mt--30">
                                                        <TabWorkWithUs listStyle="tab-style--1" itemStyle="" items={position.items}/>
                                                    </div>
                                                </div>
                                                <div className="row mt--50">                                                    
                                                    <div className="col-lg-12">
                                                        <a className="rn-button-style--2 btn-sm btn-solid" href="mailto:info@beatdata.it" target="_blank" rel="noopener noreferrer">Invia Candidatura</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="thumbnail">
                                                    <img className="w-100" src={`/assets/images/work-with-us/${position.image.fileName}`} alt={`${position.image.alt}`} title={`${position.image.title}`}/>
                                                </div>
                                            </div>
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                   
                ))}
                               
                {/* End Job Position Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default WorkWithUs
