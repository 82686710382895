import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
//import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const AnyReactComponent = ({ text }) => <div className="beatdata-map-marker"><img alt="BeatData Srl" title="BeatData Srl" src="/assets/images/about/map-marker.png"/></div>;

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 45.523460522225406,
            lng: 9.334499266429852
        },
        zoom: 17
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet title="BeatData" description="Contatti" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--18"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title text-white">Contattaci</h2>
                                    <p>Scopri di più su come possiamo aiutare la tua azienda a superare le sfide tecnologiche. Contattaci oggi per una consulenza personalizzata. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                       
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">                                        
                                        <p><a href="tel:+39 02 91943951"><strong>tel:</strong> (+39) 02 91943951</a></p>
                                        <p><a href="tel:+39 02 91943952"><strong>fax:</strong> (+39) 02 91943952</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">                                        
                                        <p><a href="mailto:info@beatdata.i"><strong>email:</strong> info@beatdata.it</a></p>
                                        <p><a href="mailto:info@pec.beatdata.it"><strong>pec:</strong> info@pec.beatdata.it</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">                                        
                                        <p>Via Cavour, 12, 20063<br/>Cernusco sul Naviglio, Milano</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                {/* <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div> */}
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}
                <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '650px', width: '100%' }}>
                        <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyDMvWUPPudE3nbsOuRe2wgnGDD7RO3__xY" }}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                        <AnyReactComponent
                            lat={45.523460522225406}
                            lng={9.334499266429852}
                            text="BeatData Srl"
                        />
                        </GoogleMapReact>
                    </div>
                </div>
                {/* End Contact Map  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </React.Fragment>
        )
    }
}
export default Contact