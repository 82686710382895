import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
    render() {
        let seoData = {
            title: 'BeatData',
            description: 'Code, Innovation, Success!',
            metadata: [
                {
                    key: 'description',
                    content: '',
                }
            ]
        }
        let getMetdata = (key) => {
            var result = '';
            for(var i = 0; i < seoData.metadata.length; i++) {
                if(seoData.metadata[i].key === key) {
                    result = seoData.metadata[i].content;
                }
            }
            return result;
        };
        const { title, description, metadata = [] } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                    {title ? title : seoData.title} | { description ? description : seoData.description }
                    </title>
                    {metadata.map((m, i) => (
                        <meta name={m.key} content={m.content ? m.content : getMetdata(m.key)}/>
                    ))}
                </Helmet>
            </React.Fragment>
        );
    }
}

export default PageHelmet;
