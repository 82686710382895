import React, { Component } from "react";

class Brand extends Component{
    render(){
        const {listStyle = '', items = [] } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list ${listStyle}`}>
                    {items.map((value , index) => (
                        <li>
                            <img src={`${value.source}`} alt={`${value.label}`} title={`${value.label}`}/>
                        </li>
                    ))}
                </ul>
            </React.Fragment>
        )
    }
}
export default Brand;