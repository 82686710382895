import React from 'react';
import { Switch, Route } from 'react-router';

//pages
import Home from './home/MainDemo';
import WorkWithUs from "./elements/WorkWithUs";
import Cookies from "./elements/Cookies";
import LegalNotes from "./elements/LegalNotes";
import Privacy from "./elements/Privacy";
import Contact from "./elements/Contact";
import CaseStudyDetails from "./elements/CaseStudyDetails";
import error404 from "./elements/error404";

const Routes = () => {
    return (
    <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
        <Route exact path={`${process.env.PUBLIC_URL}/lavora-con-noi`} component={WorkWithUs}/>
        <Route exact path={`${process.env.PUBLIC_URL}/contatti`} component={Contact}/>
        <Route exact path={`${process.env.PUBLIC_URL}/cookies`} component={Cookies}/>
        <Route exact path={`${process.env.PUBLIC_URL}/note-legali`} component={LegalNotes}/>
        <Route exact path={`${process.env.PUBLIC_URL}/privacy`} component={Privacy}/>
        <Route exact path={`${process.env.PUBLIC_URL}/case/:name`} component={CaseStudyDetails}/>
        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
        <Route component={error404}/>
      </Switch>  
    )
  }

export default Routes
