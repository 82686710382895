import React, { Component, Fragment } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import BrandOne from "../elements/Brand";

class CaseStudyDetails extends Component{
    constructor () {
        super()
        this.state = { isOpen: false }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const contents = {
            middleware_in_healthcare: {
                title: 'Middleware in Healthcare',
                subtitle: 'Web Development - Healthcare',
                className: 'rn-page-title-area pt--120 pb--190 bg_image bg_image--50',
                page: {
                    title: 'Laborati diagnostici digitali',
                    subtitle: '',
                    description: 'Per automatizzare e orchestrare tutte le operazioni alla base della manipolazione dei campioni all\'interno di un laboratorio diagnostico era necessario sviluppare una soluzione middleware. La soluzione fornita fornita da Beatdata è in grado di gestire dati provenienti da diverse fonti (strumentazione analitica, LIS) ed elaborarli in pochi secondi.',    
                },
                tecnologies: [
                    { source: '/assets/images/tecnologies/mongo.png', label: 'MongoDB' },
                    { source: '/assets/images/tecnologies/angular.png', label: 'Angular' },
                    { source: '/assets/images/tecnologies/express.png', label: 'Express JS' },
                    { source: '/assets/images/tecnologies/node.png', label: 'NodeJs' },                    
                ],
                tecnologiesStyle: 'branstyle--3'
            },
            iot_platform_for_water: {
                title: 'IoT Platform For Water',
                subtitle: 'Internet Of Things - Web Development & Data Analytics',
                className: 'rn-page-title-area pt--120 pb--190 bg_image bg_image--51',
                page: {
                    title: 'Industry 4.0 & Data Analytics',
                    subtitle: '',
                    description: 'BeatData ha realizzato per il proprio partner tecnologico la prima soluzione Industry 4.0, focalizzata su Internet of Things, Big Data, Analysis e Intelligenza Artificiale finalizzata alla riduzione sistematica delle perdite nelle reti idriche, dei consumi energetici e delle emissioni di CO2 nell\'ambiente.',    
                },
                tecnologies: [
                    { source: '/assets/images/tecnologies/mongo.png', label: 'MongoDB' },
                    { source: '/assets/images/tecnologies/node.png', label: 'NodeJs' },                    
                    { source: '/assets/images/tecnologies/php.png', label: 'PHP' },
                    { source: '/assets/images/tecnologies/azure.png', label: 'Azure' },
                    { source: '/assets/images/tecnologies/powerbi.png', label: 'PowerBI' },
                    { source: '/assets/images/tecnologies/python.png', label: 'Python' },
                ],
                tecnologiesStyle: 'branstyle--2'
            },
            b2b_offer_generator: {
                title: 'Generatore Offerte B2B',
                subtitle: 'Web Development - B2B',
                className: 'rn-page-title-area pt--120 pb--190 bg_image bg_image--51',
                page: {
                    title: 'Web Development - B2B',
                    subtitle: '',
                    description: 'Con lo scopo di fornire al Cliente di profilo internazionale, una soluzione Web centralizzata, Beatdata ha realizzato una piattaforma full-cloud per la generazione di offerte personalizzata in un contesto B2B. Il sistema, basato su stack tecnologico Microsoft .Net e permette, tramite l\’utilizzo di servizi cognitivi, la gestione puntuale ed auto-generata, di migliaia di prodotti a catalogo.',    
                },
                tecnologies: [
                    { source: '/assets/images/tecnologies/sqlserver.png', label: 'SQL Server' },
                    { source: '/assets/images/tecnologies/net.png', label: '.Net' },                    
                    { source: '/assets/images/tecnologies/jquery.png', label: 'jQuery' },
                    { source: '/assets/images/tecnologies/azuresearch.png', label: 'AzureSearch' },
                ],
                tecnologiesStyle: 'branstyle--2'
            },
            decentralized_exchange: {
                title: 'Decentralized Exchange',
                subtitle: 'Blockchain Development - Cryptocurrencies',
                className: 'rn-page-title-area pt--120 pb--190 bg_image bg_image--52',
                page: {
                    title: 'Decentralized Exchange',
                    subtitle: '',
                    description: <Fragment>Contribuendo in modo attivo nella comunità Blockchain internazionale, Beatdata ha realizzato un exchange di criptovalute decentralizzato (DEX) basato su protocollo AMM (Automated Market Maker).<br/>La piattaforma, EVM (Ethereum Virtual Machine) Compatibile, risulta essere all’avanguardia utilizzando tecnologie moderne e innovative.</Fragment>,    
                },
                tecnologies: [
                    { source: '/assets/images/tecnologies/react.png', label: 'React' },
                    { source: '/assets/images/tecnologies/ethereum.png', label: 'Ethereum' },                    
                    /* { source: '/assets/images/tecnologies/php.png', label: 'PHP' },
                    { source: '/assets/images/tecnologies/azure.png', label: 'Azure' },
                    { source: '/assets/images/tecnologies/powerbi.png', label: 'PowerBI' },
                    { source: '/assets/images/tecnologies/python.png', label: 'Python' }, */
                ],
                tecnologiesStyle: 'branstyle--1'
            },
            nft_marketplace: {
                title: 'Non-fungible token',
                subtitle: 'Blockchain Development - Non-fungible token',
                className: 'rn-page-title-area pt--120 pb--190 bg_image bg_image--53',
                page: {
                    title: 'NFT Marketplace',
                    subtitle: '',
                    description: 'Per un noto leader nel mercato degli NFT (Non Fungible Token) Beatdata ha realizzato un Social Marketplace dove gli utenti hanno non solo la possibilità di mettere in vendita Token ma anche di interagire tra loro, unendo, quindi, i concetti di Gamification con tecnologie moderne come Ethereum.',    
                },
                tecnologies: [
                    { source: '/assets/images/tecnologies/react.png', label: 'React' },
                    { source: '/assets/images/tecnologies/netcore.png', label: '.Net Core' },                    
                    { source: '/assets/images/tecnologies/azure.png', label: 'Azure' },                    
                    { source: '/assets/images/tecnologies/ethereum.png', label: 'Ethereum' },                    
                    /* { source: '/assets/images/tecnologies/php.png', label: 'PHP' },
                    { source: '/assets/images/tecnologies/azure.png', label: 'Azure' },
                    { source: '/assets/images/tecnologies/powerbi.png', label: 'PowerBI' },
                    { source: '/assets/images/tecnologies/python.png', label: 'Python' }, */
                ],
                tecnologiesStyle: 'branstyle--1'
            },
            nft_collectibles: {
                title: 'NFT Collectibles',
                subtitle: 'Blockchain Development - Non-fungible token',
                className: 'rn-page-title-area pt--120 pb--190 bg_image bg_image--54',
                page: {
                    title: 'NFT Collectibles',
                    subtitle: '',
                    description: 'BeatData, sviluppando una piattaforma dedicata, ha automatizzato i processi di creazione relativi a NFT Collectibles verso piattaforme come OpenSea aiutando quindi i creatori nella distribuzione delle loro opere d’arte digitali.',    
                },
                tecnologies: [
                    { source: '/assets/images/tecnologies/react.png', label: 'React' },                
                    { source: '/assets/images/tecnologies/ethereum.png', label: 'Ethereum' },                    
                    /* { source: '/assets/images/tecnologies/php.png', label: 'PHP' },
                    { source: '/assets/images/tecnologies/azure.png', label: 'Azure' },
                    { source: '/assets/images/tecnologies/powerbi.png', label: 'PowerBI' },
                    { source: '/assets/images/tecnologies/python.png', label: 'Python' }, */
                ],
                tecnologiesStyle: 'branstyle--1'
            },
            blockchain_notarization_gateway: {
                title: 'Blockchain Notarization Gateway',
                subtitle: 'Blockchain Development - Blockchain Notarization',
                className: 'rn-page-title-area pt--120 pb--190 bg_image bg_image--55',
                page: {
                    title: 'Notarizzazione Digitale',
                    subtitle: '',
                    description: 'Notarizzare e validare documenti è da sempre uno dei primi obiettivi per cui aziende in tutto il mondo sfruttano tecnologie come Blockchain. Beatdata ha creato una soluzione pronta all’uso per facilitare questo processo offrendo un subset dedicato di API REST, basato su OpenTimeStamp e rete Bitcoin.',    
                },
                tecnologies: [
                    { source: '/assets/images/tecnologies/angular.png', label: 'Angular' },
                    { source: '/assets/images/tecnologies/node.png', label: 'NodeJs' }, 
                    { source: '/assets/images/tecnologies/mongo.png', label: 'MongoDB' },                      
                    { source: '/assets/images/tecnologies/opentimestamp.png', label: 'Opentimestamps' }
                ],
                tecnologiesStyle: 'branstyle--1'
            },
            automatic_investment_strategy: {
                title: 'Automatic Investment Strategy',
                subtitle: 'Web Development - Investments',
                className: 'rn-page-title-area pt--120 pb--190 bg_image bg_image--56',
                page: {
                    title: 'Trading Automatizzato',
                    subtitle: '',
                    description: 'Con l’obiettivo di automatizzare le operazioni di trading e investimenti automatici, è stata realizzata una piattaforma dedicata che governa tutti i processi, dalla raccolta dei fondi al monitoraggio attivo delle posizioni.',    
                },
                tecnologies: [
                    { source: '/assets/images/tecnologies/react.png', label: 'React' },
                    { source: '/assets/images/tecnologies/python.png', label: 'Python' }, 
                    { source: '/assets/images/tecnologies/powerbi.png', label: 'PowerBI' },                      
                    { source: '/assets/images/tecnologies/mysql.png', label: 'MySQL' }
                ],
                tecnologiesStyle: 'branstyle--1'
            },
            ai_documents: {
                title: 'AI for documents search',
                subtitle: 'Artificial Intelligence',
                className: 'rn-page-title-area pt--120 pb--190 bg_image bg_image--56',
                page: {
                    title: 'AI for documents search',
                    subtitle: '',
                    description: '',    
                },
                tecnologies: [
                    { source: '/assets/images/tecnologies/react.png', label: 'React' },
                    { source: '/assets/images/tecnologies/python.png', label: 'Python' }, 
                ],
                tecnologiesStyle: 'branstyle--1'
            },
        };
        let getContent = function(key) {
            var k = key.replace(/-/g, '_');
            return contents[k];
        };
        let content = getContent(this.props.match.params.name);
        return(
            <React.Fragment>
                <PageHelmet title={content.title} />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className={content.className}  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title text-white">{content.title}</h2>
                                    <p>{content.subtitle}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>{content.page.title}</h2>
                                        <p className="subtitle">{content.page.subtitle}</p>
                                        <p>{content.page.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Tecnologies Area */}
                <div className="rn-brand-area pt--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h3>Tecnologie Utilizzate</h3>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="rn-brand-area pt--80 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandOne listStyle={content.tecnologiesStyle} items={content.tecnologies} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tecnologies Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default CaseStudyDetails;
