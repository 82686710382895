import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";


class Cookies extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet title="BeatData" description="Cookie Policy" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Cookie Policy'}   />
                {/* End Breadcrump Area */}

                {/* Start Content Area */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    <p>La presente Informativa sui cookie è applicabile all’organizzazione BeatData, in tutte le sue dimensioni e attività, e in tutte le località in cui opera, così come tutte le entità che vi afferiscono.<br/>
BeatData è il titolare del trattamento dei dati che raccoglie e tratta i dati personali da te forniti al sito&nbsp;<a href="https://www.beatdata.it"><u>www.beatdata.it</u></a></p>

<p>La nostra Informativa sui cookie ha lo scopo di illustrare in che modo tuteliamo la privacy di tutti i visitatori del nostro sito internet.</p>

<p>Nella presente Informativa, per “Dati personali” si intendono i dati relativi a una persona fisica e che ne consentono, direttamente o indirettamente, l’identificazione, come per esempio il nome o le informazioni di contatto.</p>

<p>Per eventuali domande relative al trattamento dei tuoi dati personali sul nostro sito internet, puoi fare riferimento alla nostra&nbsp;<u><a href="/it/privacy-policy">Informativa sulla privacy</a></u> o puoi contattarci inviando una e-mail al seguente indirizzo di email:&nbsp;<a href="mailto:info@beatdata.it">info@beatdata.it</a>.<br/>
Il nostro sito internet utilizza cookie, Web Beacon per migliorare le prestazioni del sito stesso e potenziare la tua esperienza di navigazione. Certe aree del nostro sito internet usano anche cookie per saperne di più su di te, in modo da poterti offrire un’esperienza di navigazione più personalizzata.</p>

<h3>Cos’è un cookie?</h3>

<p>Un cookie è un piccolo file di testo utilizzato esclusivamente dal nostro sito internet che consente al nostro sito internet di salvare blocchi di informazioni (una sorta di “identificatore”) nel tuo browser. I cookie non sono utilizzati per determinare l’identità personale di chiunque stia semplicemente visitando il nostro sito internet. I cookie ci aiutano a tracciare i flussi di traffico per stabilire la località e la lingua preferita degli utenti in modo da poterli reindirizzare alla home page del Paese corretto quando visitano il nostro sito internet. Questi cookie sono installati da noi e sono chiamati cookie primari.</p>

<h3>Come si disattivano i cookie?</h3>

<p>Se non desideri ricevere un cookie dal nostro sito internet, hai varie opzioni:<br/>
puoi effettuare l’opt-out e non riceverli più o<br/>
disattivare i cookie che sono stati installati sul tuo dispositivo.<br/>
Puoi decidere in qualsiasi momento di esprimere il tuo consenso e modificare il tuo parere sui cookie, nelle modalità descritte di seguito:<br/>
ovviamente, dato che la maggior parte dei browser prevedono l’installazione predefinita di cookie, avrai le seguenti opzioni: scegliere di accettare tutti i cookie, o effettuare l’opt-out e non riceverli automaticamente.</p>

<p>Inoltre hai l’opzione di impostare il tuo browser in modo tale da potere, di volta in volta, accettare i cookie o effettuare l’opt-out prima che essi siano installati. Inoltre, puoi regolarmente rimuovere i cookie dal tuo dispositivo attraverso il tuo browser. Tuttavia, devi tenere presente che, nel caso in cui disattivi la funzione “cookie” nel tuo browser, non potrai fruire di un’esperienza di navigazione completa del nostro sito internet. Per esempio, non potrai avvalerti del login automatico o di altre funzionalità di personalizzazione del nostro sito internet.</p>

<p>Ricorda di personalizzare le impostazioni del browser su tutti i dispositivi di cui fai uso (tablet, smartphone, computer). Esistono vari modi per configurare il browser, perciò puoi fare riferimento alla sezione “aiuto” del browser che stai utilizzando per capire come configurarlo e impostare le opzioni che prediligi.<br/>
I cookie per la misurazione dell’audience possono essere disattivati nei seguenti modi:</p>

<p>• Puoi disattivare i cookie “Google Analytics” scaricando il modulo disponibile al seguente link:&nbsp;<a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a></p>

<p>• Puoi disattivare gli Insight Tag di Linkedin modificando le tue impostazioni utente su LinkedIn:&nbsp;<a href="https://www.linkedin.com/psettings">https://www.linkedin.com/psettings</a></p>

<h3>Quali cookie utilizziamo?</h3>

<p>Più specificamente, utilizziamo cookie e altre tecnologie di tracciamento per le seguenti finalità:<br/>
Per assisterti nella navigazione;<br/>
Per assisterti nella registrazione ai nostri eventi, per consentirti di effettuare l’accesso al sito, e per esercitare la tua facoltà di fornire feedback;<br/>
Per analizzare l’utilizzo che fai dei nostri prodotti, servizi o domande;<br/>
Per fornirti assistenza relativa ai nostri messaggi promozionali e di marketing (ivi inclusa la pubblicità comportamentale)<br/>
I cookie utilizzati dal nostro sito internet svolgono quattro funzioni, illustrate qui di seguito:<br/><br/>
Cookie strettamente necessari<br/>
Cookie di prestazione<br/>
Cookie funzionali<br/>
Cookie di targeting e di tracciamento</p>

<h4>Cookie strettamente necessari</h4>

<p>Alcuni cookie che utilizziamo sono fondamentali per il funzionamento del nostro sito internet. Generalmente sono impostati soltanto in risposta ad azioni da te compiute che implicano una richiesta di servizi, come per esempio impostare le tue preferenze relative alla privacy, effettuare il log in o compilare moduli. Puoi impostare il tuo browser in modo tale da bloccare o avvertirti rispetto a questi cookie, tuttavia tieni presente che alcune parti del sito internet potrebbero non funzionare.</p>

<h4>Cookie di prestazione</h4>

<p>Alcuni cookie ci aiutano con le prestazioni e la progettazione del nostro sito internet. Questa funzione ci consente di misurare quante volte è stata visitata una pagina, sapere quali sono le pagine più popolari e quelle meno popolari, e vedere in che modo i visitatori si muovono nel sito internet. Tutte le informazioni raccolte da questi cookie sono aggregate e sono pertanto anonime. Se disattivi questi cookie, non verremo a conoscenza di quando visiti il nostro sito internet.</p>

<h4>Cookie funzionali</h4>

<p>Alcuni cookie ci aiutano a ricordare le impostazioni che hai selezionato, o ad assisterti con altre funzionalità quando navighi e utilizzi il nostro sito internet. Questo ci aiuta a ricordare le tue preferenze quando ritorni a visitare il nostro sito internet.<br/>
Questi cookie migliorano la funzionalità e la personalizzazione. Possono essere impostati da noi o da fornitori terzi i cui servizi sono stati aggiunti alle nostre pagine internet. Se non permetti l’utilizzo di questi cookie, alcune o tutte le funzionalità potrebbero non funzionare correttamente.</p>

<h4>Cookie di targeting e di tracciamento</h4>

<p>Questi cookie sono installati nel nostro sito internet dai nostri partner commerciali. Potranno essere utilizzati da queste società per costruire un profilo relativo ai tuoi interessi e offrirti pubblicità rilevanti su altri siti internet. Questi funzionano identificando unicamente il tuo browser e il dispositivo. Se non acconsenti all’utilizzo di questi cookie, non potrai ricevere la nostra pubblicità mirata offerta su diversi siti internet.</p>

<p>Questi cookie raccolgono informazioni relative all’origine della tua visita, dove si verifica la tua esposizione alla pubblicità BeatData, quali pubblicità hai visto, se sei arrivato direttamente o indirettamente al nostro sito internet, il dispositivo che utilizzi per visitare il nostro sito internet e quali download hai effettuato. Queste informazioni sono raccolte su base anonima tramite fornitori di servizi terzi.</p>

<p>Inoltre, su alcune pagine del nostro sito internet, utilizziamo cookie per comunicare con fornitori di dati terzi allo scopo di estrapolare dati relativi al tuo comportamento digitale. Ciò ci aiuta a capire e a offrirti pubblicità più rilevanti in futuro. Le informazioni che riceviamo sono interamente aggregate e anonime ma includeranno statistiche demografiche, il comportamento online, l’interesse per i prodotti e lo stile di vita. I cookie di targeting e di tracciamento sono gestiti da fornitori di servizi terzi di fiducia. Nel caso in cui volessi ricevere ulteriori informazioni relative ai nostri fornitori e su come questi cookie funzionano, puoi inviarci una e-mail al seguente indirizzo di posta elettronica: <a href="mailto:info@beatdata.it">info@beatdata.it</a>.</p>

<h3>Cosa sono i web beacon?</h3>

<p>A volte BeatData effettua pubblicità su siti internet di parti terze. Come parte del nostro sforzo di monitorare il successo delle nostre campagne pubblicitarie, potremo a volte utilizzare le tecnologie di identificazione del visitatore come i “web beacon” o “action tag”, che contano i visitatori che sono arrivati al nostro sito internet dopo essere stati esposti a un banner BeatData su un sito internet di terze parti. Non utilizziamo queste tecnologie per accedere ai tuoi dati personali, ma solo per compilare statistiche aggregate sui visitatori che arrivano sul nostro sito internet, così da valutare l’efficacia della nostra pubblicità.</p>

<h3>Modifiche Alla Presente Informativa Sui Cookie</h3>

<p>Se necessario, BeatData potrà modificare la presente Informativa sui cookie. Se verranno applicate delle modifiche alla presente Informativa, potranno volerci fino a 30 giorni lavorativi prima che le nuove pratiche in materia di privacy divengano effettive. Ti consigliamo di controllare periodicamente questa pagina per monitorare le eventuali modifiche.</p>

                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Content Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default Cookies