import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-01.png" alt="Microsoft" title="Microsoft"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.png" alt="React" title="React"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="Angular" title="Angular"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-05.png" alt="NodeJs" title="NodeJs"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-06.png" alt="NextJs" title="NextJs"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-07.png" alt="Kubernates" title="Kubernates"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-08.png" alt="Docker" title="Docker"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-09.png" alt="Redis" title="Redis"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-10.png" alt="OpenAI" title="OpenAI"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-11.png" alt="Azure" title="Azure"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-12.png" alt="Jenkins" title="Jenkins"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-13.png" alt="Elastic" title="Elastic"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-14.png" alt="Matrix42" title="Matrix42"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-15.png" alt="Pipedrive" title="Pipedrive"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-16.png" alt="MongoDB" title="MongoDB"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;