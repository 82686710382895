import React, { Component } from "react";
import {FaTwitter, /* FaInstagram ,*/ FaFacebookF, FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/beatdata.it/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/company/beatdata'},
    //{Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://mobile.twitter.com/beatdatasrl'},
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Cerchi nuove sfide?</span>
                                        <h2>Entra nella nostra squadra!</h2>
                                        <a className="rn-button-style--2" href="/lavora-con-noi">
                                            <span>Candidati</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-4 col-sm-4 col-12">
                                            <div className="footer-link">
                                                <h4>Legal</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/cookies">Informativa Cookie</a></li>
                                                    <li><a href="/note-legali">Note Legali</a></li>
                                                    <li><a href="/privacy">Privacy</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-4 col-sm-4 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Internal</h4>
                                                <ul className="ft-link">
                                                    <li><a href="https://beatdatasrl.sharepoint.com/sites/Intranet" target="_blank" rel="noopener noreferrer">Intranet</a></li>
                                                    <li><a href="https://outlook.office.com/owa/" target="_blank" rel="noopener noreferrer">Mail</a></li>
                                                    <li><a href="https://gitlab.beatdata.it/" target="_blank" rel="noopener noreferrer">Repository</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        {/* Start Single Widget  */}
                                        <div className="col-lg-4 col-sm-4 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Website</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/lavora-con-noi" target="_blank" rel="noopener noreferrer">Join</a></li>
                                                    <li><a href="/contatti" target="_blank" rel="noopener noreferrer">Contact</a></li>
                                                </ul>
                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`} target="_blank" rel="noopener noreferrer">{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                            <p className='footer-company'>Beatdata S.r.l.</p>
                                            <p className='footer-company-details'>Copyright &copy; {new Date().getFullYear()} Beatdata S.r.l. | Via Camillo Benso Conte di Cavour 12, 20063, Cernusco s/N Milano, Italia | VAT, tax code, and number of registration 03590231209 | REA number MI-2124726 | Contributed capital €10.000,00</p>                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;