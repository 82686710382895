import React, { Component } from "react";
import { FiUsers, FiCode, FiBox } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiBox />,
        title: 'System Integration Avanzata',
        description: 'Sviluppiamo sinergie operative avanzate per ottimizzare risorse e migliorare l\'efficienza. La nostra expertise nella System Integration rafforza la collaborazione tra processi aziendali, garantendo risultati duraturi e un impatto positivo sulle prestazioni complessive.',
        url: null
    },
    {
        icon: <FiCode />,
        title: 'Sviluppo Software Integrato',
        description: 'Progettiamo soluzioni software all\'avanguardia che si integrano perfettamente con i sistemi esistenti. Le nostre applicazioni robuste e scalabili aumentano l\'efficienza operativa e la produttività, garantendo un miglioramento continuo delle prestazioni aziendali.',
        url: null
    },
    {
        icon: <FiUsers />,
        title: 'Innovazione Tecnologica',
        description: 'BeatData è sinonimo di innovazione. Sviluppiamo soluzioni tecnologiche all\'avanguardia, collaborando con i nostri partner per spingere i confini della tecnologia e assicurare un miglioramento costante delle soluzioni integrate. ',
        url: null
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;