import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";


class Privacy extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet title="BeatData" description="Privacy Policy" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Privacy Policy'}   />
                {/* End Breadcrump Area */}

                {/* Start Content Area */}
                <div className="rn-blog-details pt--60 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    <p>Inserendo i dati di volta in volta i dati richiesti dal sistema necessari per l'acquisto e la trasmissione del prodotto, l'utente accetta automaticamente che BeatData gestisca tali informazioni in conformità al Regolamento (UE) 2016/679.</p>

<h3>Riferimenti Normativi</h3>
<ul className="list-style mb--30">
	<li>Direttiva 2002/58/CE - relativa al "trattamento dei dati personali e alla tutela della vita privata nel settore delle comunicazioni elettroniche"</li>
	<li>Regolamento (UE) 2016/679 del parlamento europeo e del consiglio del 27 aprile 2016 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati e che abroga la direttiva 95/46/ce (Regolamento Generale sulla Protezione dei Dati).</li>
</ul>

<h3 className="pt--30">Titolare e responsabile del trattamento</h3>
<p>Nell’ambito della consultazione di questo sito possono essere trattati dati relativi a persone identificate o identificabili.<br/>
Il &quot;Titolare&quot; del loro trattamento è BeatData Srl, Via Amendola, 7, 20063, Cernusco s/N (MI).</p>

<h3>Responsabile per la protezione dei dati</h3>
<p>Il Titolare, conformemente a quanto disposto dall’articolo 37 del Regolamento (UE) 2016/679 ha nominato un Responsabile per la protezione dei dati (DPO) contattabile all’indirizzo e-mail info@pec.beatdata.it.</p>

<h3>Luogo di trattamento dei dati</h3>
<p>I trattamenti connessi ai servizi web di questo sito hanno luogo presso la sede del titolare e sono curati solo da personale tecnico opportunamente autorizzato al trattamento ai sensi dell’art. 29 del Regolamento (UE) 2016/679, oppure da eventuali incaricati di occasionali operazioni di manutenzione.</p>

<h3>Tipi di dati trattati</h3>
<p><strong>Dati di navigazione. </strong>I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero permettere di identificare gli utenti. In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l’elaborazione. I dati potrebbero, inoltre, essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito.</p>
<p><strong>Dati forniti volontariamente dall’utente. </strong>L’invio facoltativo, esplicito e volontario di dati personali da parte dell’utente nei form di raccolta dati ed ai contatti presenti su questo sito comporta la successiva acquisizione dei dati forniti dal mittente. Tali dati verranno trattati da BeatData S.r.l., prevalentemente con mezzi informatici, al fine di dar seguito alla richiesta dell’utente. Specifiche informative sono riportate in calce ad ogni form di raccolta dati.</p>

<h3>Link ad altri siti web</h3>
<p>Questo sito potrebbe contenere link o riferimenti per l’accesso ad altri siti, quali ad esempio i social network Facebook, Instagram, Youtube, Twitter. Cliccando sugli appositi link potrai ad esempio condividere i nostri contenuti. Ti informiamo il Titolare del trattamento non controlla i cookie o le altre tecnologie di monitoraggio di tali siti web ai quali la presente Policy non si applica.</p>

<h3>Facoltatività del conferimento dei dati</h3>
<p>A parte quanto specificato per i dati di navigazione, l’utente è libero di fornire i dati propri personali. Tuttavia il loro mancato conferimento può comportare l’impossibilità di ottenere quanto richiesto.</p>

<h3>Modalità del trattamento e tempi di conservazione</h3>
<p>I dati personali sono trattati, anche mediante l’ausilio di strumenti automatizzati, per il tempo strettamente necessario a conseguire gli scopi per cui sono stati raccolti. Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati. BeatData Srl ha adottato tutte le misure di sicurezza ritenute adeguate per ridurre al minimo i rischi afferenti la riservatezza, la disponibilità e l’integrità dei dati personali raccolti e trattati.</p>

<h3>Condivisione, comunicazione e diffusione e dati</h3>
<p>I dati raccolti potranno essere trasferiti o comunicati ad altre società per attività strettamente connesse e strumentali all’operatività del servizio, come la gestione del sistema informatico. I dati personali forniti dagli utenti che inoltrano richieste di invio di materiale informativo sono utilizzati al solo fine di eseguire il servizio o la prestazione richiesta e sono comunicati a terzi nel solo caso in cui ciò sia a tal fine necessario (società che forniscono servizi di imbustamento, etichettatura, spedizione). Al di fuori di questi casi, i dati personali non saranno comunicati, né concessi ad alcuno, salvo previsione contrattuale o di legge, ovvero salvo specifico consenso richiesto all’interessato. In questo senso, i dati personali potrebbero essere trasmessi a terze parti, ma solo ed esclusivamente nel caso in cui: a) vi sia consenso esplicito a condividere i dati con terze parti; b) vi sia la necessità di condividere con terzi le informazioni al fine di prestare il servizio richiesto; c) ciò sia necessario per adempiere a richieste dell’Autorità Giudiziaria o di Pubblica Sicurezza. Nessun dato derivante dal servizio web viene diffuso.</p>

<h3>Diritti degli interessati</h3>
<p>La normativa a protezione dei dati personali prevede espressamente alcuni diritti in capo ai soggetti cui dati si riferiscono (c.d. interessato). In particolare, In relazione al trattamento dei predetti dati, Le ricordiamo il diritto di ottenere l'accesso ai dati personali che la riguardano; la rettifica dei dati (correzione di dati inesatti od integrazione), la cancellazione degli stessi o la limitazione del trattamento o di opporsi al loro trattamento, oltre al diritto alla portabilità dei dati ed alla revoca dell’eventuale consenso rilasciato.</p>
<p>Per esercitare i diritti può rivolgersi al Titolare del trattamento scrivendo a BeatData Srl, Via Amendola, 7, 20063, Cernusco s/N (MI). In alternativa può rivolgersi al Responsabile per la protezione dei dati scrivendo un messaggio di posta elettronica all’indirizzo e-mail info@pec.beatdata.it. Infine Le ricordiamo il diritto di proporre reclamo a un'autorità di controllo.</p>

<h3>Modifiche alle presenti politiche sulla privacy</h3>
<p>BeatData Srl verifica periodicamente la propria politica relativa alla privacy e alla sicurezza e, se del caso, la rivede in relazione alle modifiche normative, organizzative o dettate dall’evoluzione tecnologica. In caso di modifica delle politiche, la nuova versione sarà pubblicata in questa pagina del sito.</p>

<h3>Domande, reclami e suggerimenti</h3>
<p>Chiunque fosse interessato a maggiori informazioni, a contribuire con propri suggerimenti o avanzare reclami o contestazioni in merito alle politiche privacy, sul modo in cui la nostra Società tratta i dati personali può rivolgersi al Titolare del trattamento scrivendo a BeatData Srl, Via Amendola, 7, 20063, Cernusco s/N (MI).</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Content Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default Privacy