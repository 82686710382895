import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabWorkWithUs extends Component{
    render(){
        const { listStyle = '', itemStyle = '', items = [] } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList className={`${listStyle}`}>
                                        {items.map((value , index) => (
                                            <Tab className={`${itemStyle}_tab`}>{value.label}</Tab>
                                        ))}
                                    </TabList>
                                    {items.map((value , index) => (
                                        <TabPanel className={`${itemStyle}_tab-panel`}>
                                            <div className="single-tab-content">
                                                {value.content}
                                            </div>
                                        </TabPanel>
                                    ))}                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabWorkWithUs;