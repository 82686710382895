import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import {Link} from "react-router-dom";


const PortfolioList = [
    {
        "image": "image-2",
        "category": "System Integration",
        "businessSegment": "Manufacturing",
        "title": "Progetto di System Integration per Azienda Manifatturiera",
        "description": "Abbiamo integrato i sistemi ERP e CRM, migliorando l'efficienza operativa e la gestione dei dati.",
        "link": "/#"
    },
    {
        "image": "image-5",
        "category": "Artificial Intelligence",
        "businessSegment": "Healthcare",
        "title": "Soluzione AI per Settore Sanitario",
        "description": "Sviluppato un sistema AI per l'analisi della knowledge base aziendale, ottimizzando il servizio di supporto clienti",
        "link": "/#"
    },
    {
        "image": "image-3",
        "category": "Blockchain",
        "businessSegment": "Fintech",
        "title": "Implementazione Blockchain per Fintech",
        "description": "Realizzato una piattaforma basata su blockchain per la gestione sicura delle transazioni finanziarie, migliorando la trasparenza e la sicurezza.",
        "link": "/#"
    },
    {
        "image": "image-4",
        "category": "Cloud Solutions",
        "businessSegment": "Retail",
        "title": "Integrazione Cloud per Azienda Retail",
        "description": "Migrato l'infrastruttura IT al cloud, migliorando la scalabilità e riducendo i costi operativi.",
        "link": "/#"
    },
    {
        "image": "image-1",
        "category": "Internet of Things",
        "businessSegment": "Servizi",
        "title": "Ottimizzazione IoT per Azienda di Servizi",
        "description": "Implementato soluzioni IoT per monitorare in tempo i dati provenienti da displisitivi LoraWan, aumentando l'efficienza e riducendo i tempi di intervento.",
        "link": "/#"
    },
    {
        "image": "image-6",
        "category": "DevOps",
        "businessSegment": "Technology",
        "title": "Automazione DevOps per Azienda Tech",
        "description": "Adottato metodologie DevOps per automatizzare i flussi di lavoro di sviluppo, accelerando il rilascio di nuove funzionalità e migliorando la qualità del software.",
        "link": "/#"
    }
]
;

class Portfolio extends Component{
    render(){
        const { list = [] } = this.props;
        const items = (list.length <= 0 ? PortfolioList : list);
        let title = 'Case Studies',
        description = 'Amiamo le sfide e affrontiamo ogni progetto con passione e dedizione. Ecco alcuni esempi di come abbiamo risolto complesse esigenze aziendali attraverso soluzioni integrate innovative e collaborazioni strategiche di successo.';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {items.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4 className="title">
                                                <a href={`${value.link ? value.link : '/portfolio-details'}`}>{value.title}</a>
                                            </h4>
                                            <p>{value.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;