import React, { Component } from "react";

const standardService = [
    {
        image: '01',
        title: 'System Integration e Sviluppo Software',
        description: 'In BeatData, ci dedichiamo all\'integrazione di sistemi e allo sviluppo di software personalizzato. Utilizzando tecnologie all\'avanguardia, creiamo soluzioni scalabili che ottimizzano l\'efficienza aziendale.',
    },
    {
        image: '03',
        title: 'Artificial Intelligence',
        description: 'Offriamo servizi di sviluppo di soluzioni AI avanzate, permettendo alle aziende di sfruttare il potenziale dell\'intelligenza artificiale per migliorare le operazioni e l\'innovazione.',
    },
    {
        image: '02',
        title: 'Blockchain',
        description: 'Realizziamo soluzioni personalizzate in ambito Blockchain, Smart Contract e Token. Sviluppiamo Dapp e sistemi basati su Blockchain, offrendo consulenza tecnica e strategica.',
    },
    {
        image: '05',
        title: 'Service Management',
        description: 'Siamo Matrix42 Competence Partner Silver, specializzati nella gestione integrata di dispositivi, applicazioni e servizi, ottimizzando l\'IT aziendale.',
    },
    {
        image: '06',
        title: 'DevOps',
        description: 'Forniamo consulenza per l\'implementazione delle metodologie DevOps, automatizzando le attività di sviluppo e migliorando i processi di business.',
    },
    {
        image: '04',
        title: 'Cloud & Data Center',
        description: 'Analizziamo la tua infrastruttura e offriamo soluzioni cloud e data center personalizzate, utilizzando le migliori tecnologie disponibili.',
    },

]

class ServiceTwo extends Component{
    render(){
        let title = 'Servizi',
        description = 'Esplora le nostre competenze e scopri come possiamo aiutarti a spingere i confini della tua azienda nel mondo digitale in continua evoluzione.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {standardService.map((value , index) => (
                                <div className="col-lg-4 col-md-4 mt--30" key={index}>
                                    <div className="standard-service">
                                        <div className="thumbnai">
                                            <img src={`/assets/images/featured/corporate-${value.image}.jpg`} alt="Corporate Images"/>
                                        </div>
                                        <div className="content">
                                            <h3><a href="/#">{value.title}</a></h3>
                                            <p>{value.description}</p>                                                    
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
