import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";


class LegalNotes extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet title="BeatData" description="Note Legali" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Note Legali'}   />
                {/* End Breadcrump Area */}

                {/* Start Content Area */}
                <div className="rn-blog-details pt--60 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    <h3>Codice etico</h3>
                                        <p>Dalla Mission di BeatData derivano i Valori che stanno alla base del Codice etico, che sono essenzialmente due:</p>
                                        <ul className="list-style">
                                            <li>Il valore della reputazione e della credibilità aziendale nei confronti di tutti gli stakeholder, sia esterni che interni.</li>
                                            <li>Il valore della reciprocità che persegue un ideale di reciproco rispetto e vantaggio delle parti e la prevenzione della commissione di reati contemplati dal DL 231/2001.</li>
                                        </ul>
                                        <p className="mt--30">Per assicurare comportamenti uniformi a questi valori, il Codice Etico di BeatData esprime gli impegni e le responsabilità etiche cui sono tenuti ad uniformarsi, nella conduzione degli affari e delle attività aziendali, tutti i collaboratori di BeatData, siano essi amministratori, dipendenti, collaboratori o terzi interessati. Il Codice Etico è costituito dai Principi generali che regolano le relazioni con gli stakeholder, dalle Regole di condotta e dai Meccanismi di attuazione e controllo.</p>
                                        <h3>Condizioni generali di fornitura beni e servizi</h3>
                                        <p>Le condizioni generali di fornitura Beni e Servizi, unitamente al documento di Offerta ed agli eventuali allegati, rappresentano parte integrante e sostanziale del contratto che regolamenta le modalità, le condizioni ed i termini della vendita di hardware, software e servizi da parte di BeatData a favore del Cliente.</p>
                                        <h3>Condizioni generali di fornitura software e Application Management Services</h3>
                                        <p>Le condizioni generali di fornitura Software e Application Management Services, rappresentano parte integrante e sostanziale del contratto che regolamenta le modalità, le condizioni ed i termini della vendita di software, applicazioni e servizi da parte di BeatData a favore del Cliente.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Content Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default LegalNotes